import React from 'react';
import { withRouter } from 'react-router-dom';

class NavigationPatcher extends React.Component {
  componentDidMount() {
    // Store the original version of history.push
    this.originalPush = this.props.history.push;

    // Monkey patch history.push
    this.props.history.push = (path, state) => {
      // Define the regex pattern to match routes like "/apartments/:region/:subregion/:property"
      const pattern = /^\/apartments\/[^\/]+\/[^\/]+\/[^\/]+$/;

      // Check if the navigation should trigger a full page reload
      if (pattern.test(path)) {
        window.location.href = path;
      } else {
        // Otherwise, use the original push function
        this.originalPush(path, state);
      }
    };
  }

  componentWillUnmount() {
    // Restore the original history.push function when the component unmounts
    this.props.history.push = this.originalPush;
  }

  render() {
    // This component does not need to render anything
    return null;
  }
}

export default withRouter(NavigationPatcher);
